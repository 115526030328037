var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('section',{attrs:{"aria-label":_vm.section.headline}},[_c('div',{staticClass:"\n      component\n      position-relative\n      c-coverage\n      padding-y-24\n      bp-768:padding-y-48\n    ",class:{ dark: _vm.section.dark_mode },style:({
      'background-image': 'url(' + _vm.section.backgroundImage + ') ',
    })},[_c('div',{staticClass:"row padding-x-12 position-relative z-index-3"},[_c('common-header',{attrs:{"headline":_vm.section.headline,"sponsor":_vm.section.sponsor}})],1),(_vm.template.name === 'coverage-1')?_c('div',{staticClass:"\n        c-coverage--template-1\n        row\n        width-100\n        position-relative\n        z-index-3\n        padding-x-12\n      "},[_c('div',{staticClass:"width-100 c-coverage__icons"},[_c('div',{staticClass:"c-coverage__icons--list display-flex flex-wrap-wrap"},[_vm._l((_vm.section.coverageElements),function(element){return [(!element.is_custom)?[(element.show && element.id == 'date')?_c('div',{key:element.id,staticClass:"\n                  c-coverage__icons--item\n                  flex-basis-100\n                  bp-768:flex-basis-50\n                  bp-1024:flex-basis-33\n                  display-flex\n                  flex-direction-column\n                  align-items-center\n                  justify-content-center\n                  text-align-center\n                "},[_c('div',{staticClass:"c-coverage__icons--item-icon"},[(
                      !element.is_custom && _vm.elementIcon(element.id) != null
                    )?_c('feather',{attrs:{"size":"48px","type":_vm.elementIcon(element.id)}}):_vm._e()],1),(_vm.gameday && _vm.gameday.game && _vm.gameday.game.date)?_c('div',{staticClass:"c-coverage__primary"},[_vm._v(" "+_vm._s(_vm.gameday.game.date ? _vm.moment(_vm.gameday.game.date).format("MM.DD.YY") : "N/A")+" ")]):_vm._e(),_c('div',{staticClass:"c-coverage__secondary"},[_vm._v("Date")])]):_vm._e(),(element.show && element.id == 'time')?_c('div',{key:element.id,staticClass:"\n                  c-coverage__icons--item\n                  flex-basis-100\n                  bp-768:flex-basis-50\n                  bp-1024:flex-basis-33\n                  display-flex\n                  flex-direction-column\n                  align-items-center\n                  justify-content-center\n                  text-align-center\n                "},[_c('div',{staticClass:"c-coverage__icons--item-icon"},[(
                      !element.is_custom && _vm.elementIcon(element.id) != null
                    )?_c('feather',{attrs:{"size":"48px","type":_vm.elementIcon(element.id)}}):_vm._e()],1),(_vm.gameday && _vm.gameday.game && _vm.gameday.game.time)?_c('div',{staticClass:"c-coverage__primary"},[_vm._v(" "+_vm._s(_vm.gameday.game.time)+" ")]):_vm._e(),_c('div',{staticClass:"c-coverage__secondary"},[_vm._v("Time")])]):_vm._e(),(element.show && element.id == 'location')?_c('div',{key:element.id,staticClass:"\n                  c-coverage__icons--item\n                  flex-basis-100\n                  bp-768:flex-basis-50\n                  bp-1024:flex-basis-33\n                  display-flex\n                  flex-direction-column\n                  align-items-center\n                  justify-content-center\n                  text-align-center\n                "},[_c('div',{staticClass:"c-coverage__icons--item-icon"},[(
                      !element.is_custom && _vm.elementIcon(element.id) != null
                    )?_c('feather',{attrs:{"size":"48px","type":_vm.elementIcon(element.id)}}):_vm._e()],1),(_vm.gameday && _vm.gameday.game && _vm.gameday.game.location)?_c('div',{staticClass:"c-coverage__primary"},[_c('div',{staticClass:"margin-b-6"},[_vm._v(_vm._s(_vm.gameday.game.location))]),(
                      _vm.gameday.facility.featured != null &&
                      _vm.gameday.facility.featured
                    )?_c('a',{attrs:{"href":'/facilities/?id=' + _vm.gameday.facility.id}},[_vm._v(" "+_vm._s(_vm.gameday.facility.title)+" ")]):_vm._e()]):_vm._e(),_c('div',{staticClass:"c-coverage__secondary"},[_vm._v("Location")])]):_vm._e(),(
                  element.show &&
                  element.id == 'tv' &&
                  _vm.gameday &&
                  _vm.gameday.game &&
                  _vm.gameday.game.coverage.tv
                )?_c('div',{key:element.id,staticClass:"\n                  c-coverage__icons--item\n                  flex-basis-100\n                  bp-768:flex-basis-50\n                  bp-1024:flex-basis-33\n                  display-flex\n                  flex-direction-column\n                  align-items-center\n                  justify-content-center\n                  text-align-center\n                "},[_c('div',{staticClass:"c-coverage__icons--item-icon"},[(
                      !element.is_custom && _vm.elementIcon(element.id) != null
                    )?_c('feather',{attrs:{"size":"48px","type":_vm.elementIcon(element.id)}}):_vm._e()],1),(_vm.gameday && _vm.gameday.game && _vm.gameday.game.coverage.tv)?_c('div',{staticClass:"c-coverage__primary",domProps:{"innerHTML":_vm._s(_vm.gameday.game.coverage.tv)}}):_vm._e()]):_vm._e(),(
                  element.show &&
                  element.id == 'radio' &&
                  _vm.gameday &&
                  _vm.gameday.game &&
                  _vm.gameday.game.coverage.radio
                )?_c('div',{key:element.id,staticClass:"\n                  c-coverage__icons--item\n                  flex-basis-100\n                  bp-768:flex-basis-50\n                  bp-1024:flex-basis-33\n                  display-flex\n                  flex-direction-column\n                  align-items-center\n                  justify-content-center\n                  text-align-center\n                "},[_c('div',{staticClass:"c-coverage__icons--item-icon"},[(
                      !element.is_custom && _vm.elementIcon(element.id) != null
                    )?_c('feather',{attrs:{"size":"48px","type":_vm.elementIcon(element.id)}}):_vm._e()],1),(
                    _vm.gameday && _vm.gameday.game && _vm.gameday.game.coverage.radio
                  )?_c('div',{staticClass:"c-coverage__primary",domProps:{"innerHTML":_vm._s(_vm.gameday.game.coverage.radio)}}):_vm._e()]):_vm._e(),(
                  element.show &&
                  element.id == 'streaming' &&
                  _vm.gameday &&
                  _vm.gameday.game &&
                  _vm.gameday.game.coverage.streaming
                )?_c('div',{key:element.id,staticClass:"\n                  c-coverage__icons--item\n                  flex-basis-100\n                  bp-768:flex-basis-50\n                  bp-1024:flex-basis-33\n                  display-flex\n                  flex-direction-column\n                  align-items-center\n                  justify-content-center\n                  text-align-center\n                "},[_c('div',{staticClass:"c-coverage__icons--item-icon"},[(
                      !element.is_custom && _vm.elementIcon(element.id) != null
                    )?_c('feather',{attrs:{"size":"48px","type":_vm.elementIcon(element.id)}}):_vm._e()],1),(
                    _vm.gameday && _vm.gameday.game && _vm.gameday.game.coverage.streaming
                  )?_c('a',{staticClass:"c-coverage__primary",attrs:{"href":_vm.gameday.game.coverage.streaming}},[_vm._v(" Watch Live ")]):_vm._e()]):_vm._e(),(
                  element.show &&
                  element.id == 'audio' &&
                  _vm.gameday &&
                  _vm.gameday.game &&
                  _vm.gameday.game.coverage.audio
                )?_c('div',{key:element.id,staticClass:"\n                  c-coverage__icons--item\n                  flex-basis-100\n                  bp-768:flex-basis-50\n                  bp-1024:flex-basis-33\n                  display-flex\n                  flex-direction-column\n                  align-items-center\n                  justify-content-center\n                  text-align-center\n                "},[_c('div',{staticClass:"c-coverage__icons--item-icon"},[(
                      !element.is_custom && _vm.elementIcon(element.id) != null
                    )?_c('feather',{attrs:{"size":"48px","type":_vm.elementIcon(element.id)}}):_vm._e()],1),(
                    _vm.gameday && _vm.gameday.game && _vm.gameday.game.coverage.audio
                  )?_c('a',{staticClass:"c-coverage__primary",attrs:{"href":_vm.gameday.game.coverage.audio}},[_vm._v(" Listen Live ")]):_vm._e()]):_vm._e(),(
                  element.show &&
                  element.id == 'stats' &&
                  _vm.gameday &&
                  _vm.gameday.game &&
                  _vm.gameday.game.coverage.stats
                )?_c('div',{key:element.id,staticClass:"\n                  c-coverage__icons--item\n                  flex-basis-100\n                  bp-768:flex-basis-50\n                  bp-1024:flex-basis-33\n                  display-flex\n                  flex-direction-column\n                  align-items-center\n                  justify-content-center\n                  text-align-center\n                "},[_c('div',{staticClass:"c-coverage__icons--item-icon"},[(
                      !element.is_custom && _vm.elementIcon(element.id) != null
                    )?_c('feather',{attrs:{"size":"48px","type":_vm.elementIcon(element.id)}}):_vm._e()],1),(
                    _vm.gameday && _vm.gameday.game && _vm.gameday.game.coverage.stats
                  )?_c('a',{staticClass:"c-coverage__primary",attrs:{"href":_vm.gameday.game.coverage.stats}},[_vm._v(" Follow Live ")]):_vm._e()]):_vm._e(),(
                  element.show &&
                  element.id == 'tickets' &&
                  _vm.gameday &&
                  _vm.gameday.game &&
                  _vm.gameday.game.coverage.tickets
                )?_c('div',{key:element.id,staticClass:"\n                  c-coverage__icons--item\n                  flex-basis-100\n                  bp-768:flex-basis-50\n                  bp-1024:flex-basis-33\n                  display-flex\n                  flex-direction-column\n                  align-items-center\n                  justify-content-center\n                  text-align-center\n                "},[_vm._m(0,true),(
                    _vm.gameday && _vm.gameday.game && _vm.gameday.game.coverage.tickets
                  )?_c('a',{staticClass:"c-coverage__primary",attrs:{"href":_vm.gameday.game.coverage.tickets}},[_vm._v(" Tickets ")]):_vm._e()]):_vm._e(),(
                  element.show &&
                  element.id == 'pregame_story' &&
                  _vm.gameday &&
                  _vm.gameday.game &&
                  _vm.gameday.game.pregame_story &&
                  _vm.gameday.game.pregame_story.id
                )?_c('div',{key:element.id,staticClass:"\n                  c-coverage__icons--item\n                  flex-basis-100\n                  bp-768:flex-basis-50\n                  bp-1024:flex-basis-33\n                  display-flex\n                  flex-direction-column\n                  align-items-center\n                  justify-content-center\n                  text-align-center\n                "},[_c('div',{staticClass:"c-coverage__icons--item-icon"},[(
                      !element.is_custom && _vm.elementIcon(element.id) != null
                    )?_c('feather',{attrs:{"size":"48px","type":_vm.elementIcon(element.id)}}):_vm._e()],1),(
                    _vm.gameday &&
                    _vm.gameday.game &&
                    _vm.gameday.game.pregame_story &&
                    _vm.gameday.game.pregame_story.id
                  )?_c('a',{staticClass:"c-coverage__primary",attrs:{"href":_vm.gameday.game.pregame_story.link}},[_vm._v(" Story Preview ")]):_vm._e()]):_vm._e(),(
                  element.show &&
                  element.id == 'teamStoreURL' &&
                  _vm.section &&
                  _vm.section.teamStoreURL
                )?_c('div',{key:element.id,staticClass:"\n                  c-coverage__icons--item\n                  flex-basis-100\n                  bp-768:flex-basis-50\n                  bp-1024:flex-basis-33\n                  display-flex\n                  flex-direction-column\n                  align-items-center\n                  justify-content-center\n                  text-align-center\n                "},[_c('div',{staticClass:"c-coverage__icons--item-icon"},[(
                      !element.is_custom && _vm.elementIcon(element.id) != null
                    )?_c('feather',{attrs:{"size":"48px","type":_vm.elementIcon(element.id)}}):_vm._e()],1),(_vm.section.teamStoreURL)?_c('a',{staticClass:"c-coverage__primary",attrs:{"href":_vm.section.teamStoreURL}},[_vm._v(" Shop Online ")]):_vm._e()]):_vm._e()]:_vm._e(),(element.is_custom && element.show)?_c('div',{key:element.id,staticClass:"\n                c-coverage__icons--item\n                flex-basis-100\n                bp-768:flex-basis-50\n                bp-1024:flex-basis-33\n                display-flex\n                flex-direction-column\n                align-items-center\n                justify-content-center\n                text-align-center\n              "},[_c('div',{staticClass:"c-coverage__icons--item-icon custom"},[(element.is_custom && element.icon)?_c('i',{class:element.icon}):_vm._e()]),(!element.link_url)?_c('div',{staticClass:"c-coverage__primary"},[_vm._v(" "+_vm._s(element.link_text)+" ")]):_vm._e(),(element.link_url)?_c('a',{staticClass:"c-coverage__primary",attrs:{"href":element.link_url}},[_vm._v(_vm._s(element.link_text))]):_vm._e()]):_vm._e()]})],2)])]):_vm._e(),(_vm.template.name === 'coverage-2')?_c('div',{staticClass:"\n        c-coverage--template-2\n        row\n        width-100\n        position-relative\n        z-index-3\n        padding-x-12\n      "},[_c('div',{staticClass:"c-coverage__icons--list bp-768:padding-x-24"},[_vm._l((_vm.section.coverageElements),function(element){return [[(!element.is_custom)?[(element.show && element.id == 'date')?_c('div',{key:element.id,staticClass:"c-coverage__icons--item display-flex"},[_c('div',{staticClass:"\n                    bp-768:display-none\n                    display-flex\n                    c-coverage__icons--item-icon\n                  "},[(
                      !element.is_custom && _vm.elementIcon(element.id) != null
                    )?_c('feather',{attrs:{"size":"28px","type":_vm.elementIcon(element.id)}}):_vm._e()],1),_c('div',{staticClass:"\n                    c-coverage__icons--item-icon\n                    display-none\n                    bp-768:display-block\n                  "},[(
                      !element.is_custom && _vm.elementIcon(element.id) != null
                    )?_c('feather',{attrs:{"size":"48px","type":_vm.elementIcon(element.id)}}):_vm._e()],1),_c('div',{staticClass:"c-coverage__icons--item-content"},[(_vm.gameday && _vm.gameday.game && _vm.gameday.game.date)?_c('div',{staticClass:"\n                      c-coverage__primary\n                      font-size-30\n                      bp-768:font-size-48\n                    "},[_vm._v(" "+_vm._s(_vm.gameday.game.date ? _vm.moment(_vm.gameday.game.date).format("MM.DD.YY") : "N/A")+" ")]):_vm._e(),_c('div',{staticClass:"c-coverage__secondary"},[_vm._v("Date")])])]):_vm._e(),(element.show && element.id == 'time')?_c('div',{key:element.id,staticClass:"c-coverage__icons--item display-flex"},[_c('div',{staticClass:"\n                    bp-768:display-none\n                    display-flex\n                    c-coverage__icons--item-icon\n                  "},[(
                      !element.is_custom && _vm.elementIcon(element.id) != null
                    )?_c('feather',{attrs:{"size":"28px","type":_vm.elementIcon(element.id)}}):_vm._e()],1),_c('div',{staticClass:"\n                    c-coverage__icons--item-icon\n                    display-none\n                    bp-768:display-block\n                  "},[(
                      !element.is_custom && _vm.elementIcon(element.id) != null
                    )?_c('feather',{attrs:{"size":"48px","type":_vm.elementIcon(element.id)}}):_vm._e()],1),_c('div',{staticClass:"c-coverage__icons--item-content"},[(_vm.gameday && _vm.gameday.game && _vm.gameday.game.time)?_c('div',{staticClass:"\n                      c-coverage__primary\n                      font-size-30\n                      bp-768:font-size-48\n                    "},[_vm._v(" "+_vm._s(_vm.gameday.game.time)+" ")]):_vm._e(),_c('div',{staticClass:"c-coverage__secondary"},[_vm._v("Time")])])]):_vm._e(),(element.show && element.id == 'location')?_c('div',{key:element.id,staticClass:"c-coverage__icons--item display-flex"},[_c('div',{staticClass:"\n                    bp-768:display-none\n                    display-flex\n                    c-coverage__icons--item-icon\n                  "},[(
                      !element.is_custom && _vm.elementIcon(element.id) != null
                    )?_c('feather',{attrs:{"size":"28px","type":_vm.elementIcon(element.id)}}):_vm._e()],1),_c('div',{staticClass:"\n                    c-coverage__icons--item-icon\n                    display-none\n                    bp-768:display-block\n                  "},[(
                      !element.is_custom && _vm.elementIcon(element.id) != null
                    )?_c('feather',{attrs:{"size":"48px","type":_vm.elementIcon(element.id)}}):_vm._e()],1),_c('div',{staticClass:"c-coverage__icons--item-content"},[_c('div',{staticClass:"\n                      c-coverage__primary\n                      font-size-30\n                      bp-768:font-size-48\n                    "},[_vm._v(" Location ")]),(_vm.gameday && _vm.gameday.game && _vm.gameday.game.location)?_c('div',{staticClass:"c-coverage__secondary"},[_c('div',{staticClass:"margin-b-6"},[_vm._v(_vm._s(_vm.gameday.game.location))]),(
                        _vm.gameday.facility.featured != null &&
                        _vm.gameday.facility.featured
                      )?_c('a',{attrs:{"href":'/facilities/?id=' + _vm.gameday.facility.id}},[_vm._v(" "+_vm._s(_vm.gameday.facility.title)+" ")]):_vm._e()]):_vm._e()])]):_vm._e(),(
                  element.show &&
                  element.id == 'tv' &&
                  _vm.gameday &&
                  _vm.gameday.game &&
                  _vm.gameday.game.coverage.tv
                )?_c('div',{key:element.id,staticClass:"c-coverage__icons--item display-flex"},[_c('div',{staticClass:"\n                    bp-768:display-none\n                    display-flex\n                    c-coverage__icons--item-icon\n                  "},[(
                      !element.is_custom && _vm.elementIcon(element.id) != null
                    )?_c('feather',{attrs:{"size":"28px","type":_vm.elementIcon(element.id)}}):_vm._e()],1),_c('div',{staticClass:"\n                    c-coverage__icons--item-icon\n                    display-none\n                    bp-768:display-block\n                  "},[(
                      !element.is_custom && _vm.elementIcon(element.id) != null
                    )?_c('feather',{attrs:{"size":"48px","type":_vm.elementIcon(element.id)}}):_vm._e()],1),_c('div',{staticClass:"c-coverage__icons--item-content"},[(_vm.gameday && _vm.gameday.game && _vm.gameday.game.coverage.tv)?_c('div',{staticClass:"\n                      c-coverage__primary\n                      font-size-30\n                      bp-768:font-size-48\n                    ",domProps:{"innerHTML":_vm._s(_vm.gameday.game.coverage.tv)}}):_vm._e()])]):_vm._e(),(
                  element.show &&
                  element.id == 'radio' &&
                  _vm.gameday &&
                  _vm.gameday.game &&
                  _vm.gameday.game.coverage.radio
                )?_c('div',{key:element.id,staticClass:"c-coverage__icons--item display-flex"},[_c('div',{staticClass:"\n                    bp-768:display-none\n                    display-flex\n                    c-coverage__icons--item-icon\n                  "},[(
                      !element.is_custom && _vm.elementIcon(element.id) != null
                    )?_c('feather',{attrs:{"size":"28px","type":_vm.elementIcon(element.id)}}):_vm._e()],1),_c('div',{staticClass:"\n                    c-coverage__icons--item-icon\n                    display-none\n                    bp-768:display-block\n                  "},[(
                      !element.is_custom && _vm.elementIcon(element.id) != null
                    )?_c('feather',{attrs:{"size":"48px","type":_vm.elementIcon(element.id)}}):_vm._e()],1),_c('div',{staticClass:"c-coverage__icons--item-content"},[(
                      _vm.gameday && _vm.gameday.game && _vm.gameday.game.coverage.radio
                    )?_c('div',{staticClass:"\n                      c-coverage__primary\n                      font-size-30\n                      bp-768:font-size-48\n                    ",domProps:{"innerHTML":_vm._s(_vm.gameday.game.coverage.radio)}}):_vm._e()])]):_vm._e(),(
                  element.show &&
                  element.id == 'streaming' &&
                  _vm.gameday &&
                  _vm.gameday.game &&
                  _vm.gameday.game.coverage.streaming
                )?_c('div',{key:element.id,staticClass:"c-coverage__icons--item display-flex"},[_c('div',{staticClass:"\n                    bp-768:display-none\n                    display-flex\n                    c-coverage__icons--item-icon\n                  "},[(
                      !element.is_custom && _vm.elementIcon(element.id) != null
                    )?_c('feather',{attrs:{"size":"28px","type":_vm.elementIcon(element.id)}}):_vm._e()],1),_c('div',{staticClass:"\n                    c-coverage__icons--item-icon\n                    display-none\n                    bp-768:display-block\n                  "},[(
                      !element.is_custom && _vm.elementIcon(element.id) != null
                    )?_c('feather',{attrs:{"size":"48px","type":_vm.elementIcon(element.id)}}):_vm._e()],1),_c('div',{staticClass:"c-coverage__icons--item-content"},[(
                      _vm.gameday &&
                      _vm.gameday.game &&
                      _vm.gameday.game.coverage.streaming
                    )?_c('a',{staticClass:"\n                      c-coverage__primary\n                      font-size-30\n                      bp-768:font-size-48\n                    ",attrs:{"href":_vm.gameday.game.coverage.streaming}},[_vm._v(" Watch Live ")]):_vm._e()])]):_vm._e(),(
                  element.show &&
                  element.id == 'audio' &&
                  _vm.gameday &&
                  _vm.gameday.game &&
                  _vm.gameday.game.coverage.audio
                )?_c('div',{key:element.id,staticClass:"c-coverage__icons--item display-flex"},[_c('div',{staticClass:"\n                    bp-768:display-none\n                    display-flex\n                    c-coverage__icons--item-icon\n                  "},[(
                      !element.is_custom && _vm.elementIcon(element.id) != null
                    )?_c('feather',{attrs:{"size":"28px","type":_vm.elementIcon(element.id)}}):_vm._e()],1),_c('div',{staticClass:"\n                    c-coverage__icons--item-icon\n                    display-none\n                    bp-768:display-block\n                  "},[(
                      !element.is_custom && _vm.elementIcon(element.id) != null
                    )?_c('feather',{attrs:{"size":"48px","type":_vm.elementIcon(element.id)}}):_vm._e()],1),_c('div',{staticClass:"c-coverage__icons--item-content"},[(
                      _vm.gameday && _vm.gameday.game && _vm.gameday.game.coverage.audio
                    )?_c('a',{staticClass:"\n                      c-coverage__primary\n                      font-size-30\n                      bp-768:font-size-48\n                    ",attrs:{"href":_vm.gameday.game.coverage.audio}},[_vm._v(" Listen Live ")]):_vm._e()])]):_vm._e(),(
                  element.show &&
                  element.id == 'stats' &&
                  _vm.gameday &&
                  _vm.gameday.game &&
                  _vm.gameday.game.coverage.stats
                )?_c('div',{key:element.id,staticClass:"c-coverage__icons--item display-flex"},[_c('div',{staticClass:"\n                    bp-768:display-none\n                    display-flex\n                    c-coverage__icons--item-icon\n                  "},[(
                      !element.is_custom && _vm.elementIcon(element.id) != null
                    )?_c('feather',{attrs:{"size":"28px","type":_vm.elementIcon(element.id)}}):_vm._e()],1),_c('div',{staticClass:"\n                    c-coverage__icons--item-icon\n                    display-none\n                    bp-768:display-block\n                  "},[(
                      !element.is_custom && _vm.elementIcon(element.id) != null
                    )?_c('feather',{attrs:{"size":"48px","type":_vm.elementIcon(element.id)}}):_vm._e()],1),_c('div',{staticClass:"c-coverage__icons--item-content"},[(
                      _vm.gameday && _vm.gameday.game && _vm.gameday.game.coverage.stats
                    )?_c('a',{staticClass:"\n                      c-coverage__primary\n                      font-size-30\n                      bp-768:font-size-48\n                    ",attrs:{"href":_vm.gameday.game.coverage.stats}},[_vm._v(" Follow Live ")]):_vm._e()])]):_vm._e(),(
                  element.show &&
                  element.id == 'tickets' &&
                  _vm.gameday &&
                  _vm.gameday.game &&
                  _vm.gameday.game.coverage.tickets
                )?_c('div',{key:element.id,staticClass:"c-coverage__icons--item display-flex"},[_vm._m(1,true),_vm._m(2,true),_c('div',{staticClass:"c-coverage__icons--item-content"},[(
                      _vm.gameday && _vm.gameday.game && _vm.gameday.game.coverage.tickets
                    )?_c('a',{staticClass:"\n                      c-coverage__primary\n                      font-size-30\n                      bp-768:font-size-48\n                    ",attrs:{"href":_vm.gameday.game.coverage.tickets}},[_vm._v(" Tickets ")]):_vm._e()])]):_vm._e(),(
                  element.show &&
                  element.id == 'pregame_story' &&
                  _vm.gameday &&
                  _vm.gameday.game &&
                  _vm.gameday.game.pregame_story &&
                  _vm.gameday.game.pregame_story.id
                )?_c('div',{key:element.id,staticClass:"c-coverage__icons--item display-flex"},[_c('div',{staticClass:"\n                    bp-768:display-none\n                    display-flex\n                    c-coverage__icons--item-icon\n                  "},[(
                      !element.is_custom && _vm.elementIcon(element.id) != null
                    )?_c('feather',{attrs:{"size":"28px","type":_vm.elementIcon(element.id)}}):_vm._e()],1),_c('div',{staticClass:"\n                    c-coverage__icons--item-icon\n                    display-none\n                    bp-768:display-block\n                  "},[(
                      !element.is_custom && _vm.elementIcon(element.id) != null
                    )?_c('feather',{attrs:{"size":"48px","type":_vm.elementIcon(element.id)}}):_vm._e()],1),_c('div',{staticClass:"c-coverage__icons--item-content"},[(
                      _vm.gameday &&
                      _vm.gameday.game &&
                      _vm.gameday.game.pregame_story &&
                      _vm.gameday.game.pregame_story.id
                    )?_c('a',{staticClass:"\n                      c-coverage__primary\n                      font-size-30\n                      bp-768:font-size-48\n                    ",attrs:{"href":_vm.gameday.game.pregame_story.link}},[_vm._v(" Story Preview ")]):_vm._e()])]):_vm._e(),(
                  element.show &&
                  element.id == 'teamStoreURL' &&
                  _vm.section &&
                  _vm.section.teamStoreURL
                )?_c('div',{key:element.id,staticClass:"c-coverage__icons--item display-flex"},[_c('div',{staticClass:"\n                    bp-768:display-none\n                    display-flex\n                    c-coverage__icons--item-icon\n                  "},[(
                      !element.is_custom && _vm.elementIcon(element.id) != null
                    )?_c('feather',{attrs:{"size":"28px","type":_vm.elementIcon(element.id)}}):_vm._e()],1),_c('div',{staticClass:"\n                    c-coverage__icons--item-icon\n                    display-none\n                    bp-768:display-block\n                  "},[(
                      !element.is_custom && _vm.elementIcon(element.id) != null
                    )?_c('feather',{attrs:{"size":"48px","type":_vm.elementIcon(element.id)}}):_vm._e()],1),_c('div',{staticClass:"c-coverage__icons--item-content"},[(_vm.section && _vm.section.teamStoreURL)?_c('a',{staticClass:"\n                      c-coverage__primary\n                      font-size-30\n                      bp-768:font-size-48\n                    ",attrs:{"href":_vm.section.teamStoreURL}},[_vm._v(" Shop Online ")]):_vm._e()])]):_vm._e()]:_vm._e(),(element.is_custom)?[_c('div',{key:element.id,staticClass:"c-coverage__icons--item display-flex"},[_c('div',{staticClass:"c-coverage__icons--item-icon custom"},[(element.is_custom && element.icon)?_c('i',{class:element.icon}):_vm._e()]),_c('div',{staticClass:"c-coverage__icons--item-content"},[(!element.link_url)?_c('div',{staticClass:"\n                      c-coverage__primary\n                      font-size-30\n                      bp-768:font-size-48\n                    "},[_vm._v(" "+_vm._s(element.link_text)+" ")]):_vm._e(),(element.link_url)?_c('a',{staticClass:"\n                      c-coverage__primary\n                      font-size-30\n                      bp-768:font-size-48\n                    ",attrs:{"href":element.link_url}},[_vm._v(_vm._s(element.link_text))]):_vm._e()])])]:_vm._e()]]})],2)]):_vm._e()])])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"c-coverage__icons--item-icon custom"},[_c('i',{staticClass:"sf-tickets-2"})])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"\n                    bp-768:display-none\n                    display-flex\n                    c-coverage__icons--item-icon\n                    custom\n                  "},[_c('i',{staticClass:"sf-tickets-2"})])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"\n                    c-coverage__icons--item-icon\n                    custom\n                    display-none\n                    bp-768:display-block\n                  "},[_c('i',{staticClass:"sf-tickets-2"})])}]

export { render, staticRenderFns }